<template>
  <VAppBar class="text-caption" height="50">
    <v-row>
      <v-col class="bottom pt-4 px-md-0" cols="12">
        <v-container class="py-lg-0 px-lg-0">
          <v-row justify="space-between" align="center">
            <v-col cols="8" lg="2" class="px-0">
              <nuxt-link to="/">
                <v-img
                  width="200"
                  src="https://epd.directus.app/assets/8932063c-5a11-4ca1-88fe-8de66054f8b8"
                  srcset="https://epd.directus.app/assets/8932063c-5a11-4ca1-88fe-8de66054f8b8 1x, https://epd.directus.app/assets/8932063c-5a11-4ca1-88fe-8de66054f8b8 2x"
                  alt="Containerdienst.de" />
              </nuxt-link>
            </v-col>

            <v-col cols="6" class="d-none d-lg-block">
              <v-stepper v-model="activeStep" alt-labels flat>
                <v-stepper-header>
                  <div class="cursor-pointer mr-6" @click="navigateTo('/warenkorb')">
                    <v-stepper-item ref="cartstep" :title="t('cart')" value="cart" />
                  </div>
                  <v-divider />

                  <v-stepper-item
                    :title="t('checkout')"
                    value="checkout"
                    :class="{ 'v-stepper-item--selected': currentPath.includes('kasse') }" />

                  <v-divider />

                  <v-stepper-item
                    :title="t('confirmation')"
                    value="confirmation"
                    :class="{
                      'v-stepper-item--selected': currentPath.includes('bestellbestaetigung'),
                    }" />
                </v-stepper-header>
              </v-stepper>
            </v-col>

            <v-col cols="4" lg="2" class="px-lg-0">
              <v-row align="center">
                <v-col />
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </VAppBar>
</template>
<script setup lang="ts">
const { t } = useI18n()
const route = useRoute()
const cartstep = ref()
const currentPath = computed(() => route.path)

const activeStep = computed(() =>
  currentPath.value.includes('warenkorb')
    ? 'cart'
    : currentPath.value.includes('kasse')
      ? 'checkout'
      : 'confirmation',
)

onMounted(() => {
  if (currentPath.value.includes('kasse')) {
    const element = cartstep.value.$el as HTMLElement
    element?.removeAttribute('disabled')
  }
})
</script>

<style lang="scss" scoped>
.v-stepper-header {
  :deep(.v-avatar) {
    width: 11px !important;
    height: 11px !important;
    font-size: 0 !important;
    margin-top: 5px;
  }
  :deep(.v-stepper-item__title) {
    text-transform: uppercase;
  }
}
</style>
